import React from 'react';
import css from './Service.module.css';
import cn from 'classnames';
import { graphql, StaticQuery } from "gatsby"
import { ImageShowAnimated } from "../../shared/components/ImageShowAnimated"
import { getStaticPath } from "../../shared/config"
import { AboutContent } from "../about/About"

export default class Services extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
           bigPic: file(relativePath: { eq: "contacts/img_contacts.png" }) { ...imgContactBig },
            ts: file(relativePath: { eq: "loghi/ts.png" }) { ...squareImage },
            js: file(relativePath: { eq: "loghi/js.png" }) { ...squareImage },
            angular2: file(relativePath: { eq: "loghi/angular2.png" }) { ...squareImage },
            react: file(relativePath: { eq: "loghi/react.png" }) { ...squareImage },
            ngrx: file(relativePath: { eq: "loghi/ngrx.png" }) { ...squareImage },
            rxjs: file(relativePath: { eq: "loghi/rxjs.png" }) { ...squareImage },
            redux: file(relativePath: { eq: "loghi/redux.png" }) { ...squareImage },
           }
        `}
        render={data => <ServicesContent {...this.props}  data={data} /> }
      />
    )
  }
}

export  class ServicesContent extends React.Component {
  render() {
    return (
      <div>
        <div className="page_wrapper">

          <div
            className="page_wrapper_side_text"
          >
            <div className="page_wrapper_side_text_inner">
              <h1 className="title_text_l">
                SERVICES
              </h1>
              <h2 className={cn('title_text_m', css.page_logo_second_line)}>
                How I can help you...
              </h2>

              <div className="grid_2x2">
                <div className="item">
                  <div style={{padding:10}}>
                    <div className="separator"></div>

                    <span className="title_text_m">
                      <i className="fa fa-leanpub"></i> Training
                    </span> <br/><br/>

                    <div className="paragraph">Angular, React, Javascript ES6, Redux, NGRX training courses for teams and developers.
                      <br/>Currently in italian only.
                      <br />Visit the <a href="../training">training section</a> to get more info.

                      <br/>


                    </div>

                    <br/>
                    <div>
                      Some Clients: <span className="text-secondary">Sky,
                      Accenture, Banca Italia, Engineering, Presidenza del Consiglio, Cineca, Seac, Team System, Beghelli, Zucchetti, Insiel Mercato, Green Peace, Phoenix and many others
                    </span>
                    </div>

                    <br/>
                  </div>

                </div>


                <div className="item">
                  <div style={{padding:10}}>
                    <div className="separator"></div>

                    <span className="title_text_m">
                    <i className="fa fa-check"></i> Code Review
                  </span> <br/><br/>
                    <div className="paragraph">
                      Are you sure your team has made the right choices and your code is clean, scalable, maintainable? <br/>
                      <a href="mailto:hello@fabiobiondi.io&subject=code review">Contact me</a> to schedule <strong>code reviews</strong>, <strong>mentoring</strong> and <strong>training</strong> sessions.

                    </div>
                    <br/>
                    {/*<Img fluid={this.props.data.angular2.childImageSharp.fluid} alt="" />*/}
                    <img alt="logo"
                         className={css.social_icon}
                         src={this.props.data.angular2.childImageSharp.fluid.src} width={55}/>
                    <img alt="logo"
                         className={css.social_icon}
                         src={this.props.data.react.childImageSharp.fluid.src} width={55}/>
                    <img alt="logo"
                         className={css.social_icon}
                         src={this.props.data.ngrx.childImageSharp.fluid.src} width={55}/>
                    <img alt="logo"
                         className={css.social_icon}
                         src={this.props.data.redux.childImageSharp.fluid.src} width={55}/>
                    <img alt="logo"
                         className={css.social_icon}
                         src={this.props.data.js.childImageSharp.fluid.src} width={55}/>
                  </div>
                </div>

                <div className="item">
                  <div style={{padding:10}}>
                    <div className="separator"></div>

                    <span className="title_text_m">
                    <i className="fa fa-calendar-o"></i> Events
                  </span> <br/><br/>
                    <div className="paragraph">
                      I'm a Google Developer Expert, speaker in a lot of italian conferences and I run my own events & meetups through Italy.
                      <br/>
                      <br/>
                      <a href="mailto:hello@fabiobiondi.io&subject=public_speaking">Contact me</a> if you organize something interesting about the topics I love.
                      <br/>
                      Visit the <a href="../gallery">Photo</a> section.
                    </div>
                  </div>
                </div>
                <br/>


                <div className="item">
                  <div style={{padding:10}}>
                    <div className="separator"></div>

                    <span className="title_text_m">
                    <i className="fa fa-code"></i> Code
                  </span> <br/><br/>

                    <div className="paragraph">
                      Me and my network of collaborators can help you in several Front-End activities, especially:
                      <li>Create Angular & React Components</li>
                      <li>Choose technologies, patterns and solutions</li>
                      <li>Data architectures in Redux, React-Redux & NGRX</li>
                      <li>UIKIT development</li>
                      <li>More...</li>
                    </div>

                    <br/>
                    <br/>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <ImageShowAnimated
            className={cn('page_wrapper_side', 'page_wrapper_side_image', 'clip-circle')}
            image={this.props.data.bigPic.childImageSharp.fluid.src}
          />
        </div>
      </div>
    )

  }
}


