import React from "react"
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../shared/css/typography.css';
import '../shared/css/ui-elements.css';
import '../shared/css/styles.css';
import '../shared/css/page.css';
import SEO from "../core/seo"
import Services from "../layouts/services/Services"
import Layout from "../layouts/Layout"

const ServicesPage = props => (       <Layout {...props}>
  <SEO title="Services"/>
  <Services {...props}/>
</Layout>)

export default ServicesPage

